<template>
  <div class="uk-container" v-if="order">
    <h3 class="uk-text-primary">{{ retailerName }}</h3>
    <product-list v-model="order"></product-list>
    <form class="uk-margin" v-if="!placed" @submit.prevent="sendOrder">
      <input
        type="text"
        class="uk-input"
        v-model="custommer"
        placeholder="Imię i nazwisko / FB login"
      />
      <div class="uk-text-danger uk-text-small" v-if="custommerError">
        Wprowadź poprawne dane (min. 5 znaków)
      </div>
      <div class="uk-text-center uk-margin">
        <button class="sendOrder" @click.prevent="sendOrder">
          Zamów
        </button>
        <router-link tag="button" class="return" :to="{ name: 'cart' }">
          Wróć
        </router-link>
      </div>
    </form>
  </div>
  <div v-else class="uk-container">
    <h4 class="uk-text-primary">Zamówienie nie istnieje</h4>
    <div class="uk-text-justify">
      Zamówienie najprawdopodobniej zostało już złożone. Jeśli jesteś
      pewien/pewna, że tak się nie stało przejdź jeszcze raz do
      <router-link :to="{ name: 'cart' }">koszyka</router-link>.
    </div>
  </div>
</template>
<script>
import ProductList from '../components/Cart/ProductList'

import { modal } from 'uikit'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderPreview',
  components: {
    ProductList
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      order: null,
      placed: false,
      retailerName: null,
      custommerError: false,
      custommer: ''
    }
  },
  computed: {
    ...mapGetters([
      'retailerNameByActionId',
      'getPaymentDetailsForAction',
      'getCartOrderById'
    ]),
    total() {
      return (
        this.order.products
          .reduce((a, b) => a + b.quantity * b.price, 0)
          .toFixed(2) || 0
      )
    }
  },
  created() {
    this.order = this.getCartOrderById(this.id)

    if (this.order) {
      this.retailerName = this.retailerNameByActionId(this.order.actionId)
    }
  },
  methods: {
    sendOrder() {
      this.custommerError = false
      if (!this.custommer || this.custommer.length < 5) {
        this.custommerError = true
        return
      }
      this.$store
        .dispatch('sendOrder', {
          custommer: this.custommer,
          total: this.total,
          ...this.order,
          payment: this.getPaymentDetailsForAction(this.actionId)
        })
        .then(order => {
          this.placed = true
          this.$store.dispatch('removeOrderFromCart', order.actionId)

          modal
            .dialog(
              '<p class="uk-modal-body uk-text-center uk-text-primary">Zamówienie złożone pomyślnie</p>'
            )
            .$el.addEventListener('hidden', () => {
              this.$router.push({ name: 'order', params: { id: this.id } })
            })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './assets/theme.scss';

.wrapper {
  @extend .uk-modal-body;
}
button.sendOrder,
button.return {
  @extend .uk-button;
  @extend .uk-margin-small-right;
  @extend .uk-margin-small-left;
  @extend .uk-border-rounded;
  text-transform: none;
}
button.sendOrder {
  @extend .uk-button-primary;
}
button.return {
  @extend .uk-button-secondary;
}
</style>
